import React from 'react'
import './home.css'
import UnderConstruction from '../underContruction/underConstruction';

function home() {
  return (
    <div className='home_container'> 
        <UnderConstruction /> 
    </div>
  )
}

export default home