import React from 'react'
import './underConstruction.css'


function underConstruction() {
  
  const randomNum = Math.random();
  let logoUrl = 'Assets/BW_Red.png';

  if(randomNum <=0.66 && randomNum>0.33 ){
    logoUrl = 'Assets/BW_Yellow.png'
  }
  if(randomNum <=1 && randomNum>0.66 ){
    logoUrl = 'Assets/BW_Blue.png'
  }
  console.log(logoUrl)
  return (
    <div className='underContruction'>
      <img className='logo' src={logoUrl} alt="" />
        <img className='opening' src="Assets/resurrection-concept-crucifixion-jesus-christ-cross-sunset.jpg" alt="" />
        <div className="shade"> </div>
        <div className="info1">
          <div className="left">
          The best Bible{'\u00A0'}
          </div>
          <div className="right">
            reading experience
          </div>
        </div>
        <div className="info2">
            is yet to come
        </div>
    </div>
  )
}

export default underConstruction